import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { API_URL, getValFromLS, setValToLS } from "../utils"

const CompletePage = () => {
  useEffect(() => {
    const init = async () => {
      if (getValFromLS("complete", true)) {
        const url = new URL(`${API_URL}/complete`)
        const complete = JSON.parse(getValFromLS("complete", true))
        const body = {
          id: complete.id,
          contact: complete.contact,
          shipping: complete.shipping,
          order: complete.order,
          totals: complete.totals,
          payment: complete.payment,
        }

        try {
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          })
          const json = await response.json()
          if (json && json.resp === 1) {
            setValToLS("complete", null, true)
            navigate(`/receipt/${json.id}`)
          } else {
            //setValToLS("complete", null, true)
            //setValToLS("order", null, true)
            //setValToLS("member", null, true)
            navigate("/")
          }
        } catch (error) {
          navigate("/")
        }
      } else {
        navigate("/")
      }
    }

    init()
  }, [])

  return <div>Please wait...</div>
}

export default CompletePage
